exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".contentTitle-module-contentTitle .contentTitle-module-title{font-size:24px}.contentTitle-module-contentTitle .contentTitle-module-pre_description{font-weight:700;font-size:13px;margin:48px 0 16px}.contentTitle-module-contentTitle button{margin-top:40px;max-width:470px}@media(min-width: 1120px){.contentTitle-module-contentTitle .contentTitle-module-description{font-size:18px}.contentTitle-module-contentTitle .contentTitle-module-pre_description{font-size:15px}.contentTitle-module-contentTitle button{margin-top:64px}}", "",{"version":3,"sources":["/opt/buildhome/repo/src/components/ContentTitle/contentTitle.module.scss"],"names":[],"mappings":"AAKI,6DACI,cAAA,CAGJ,uEACI,eAAA,CACA,cAAA,CACA,kBAAA,CAGJ,yCACI,eAAA,CACA,eAAA,CAGJ,0BACI,mEACI,cAAA,CAGJ,uEACI,cAAA,CAGJ,yCACI,eAAA,CAAA","file":"contentTitle.module.scss","sourcesContent":["@import '../../assets/scss/uikit/variables';\n@import '../../assets/scss/global/variables-custom';\n\n.contentTitle {\n\n    .title {\n        font-size: 24px;\n    }\n\n    .pre_description {\n        font-weight: 700;\n        font-size: 13px;\n        margin: 48px 0 16px;\n    }\n\n    button {\n        margin-top: 40px;\n        max-width: 470px;\n    }\n\n    @media (min-width: $breakpoint-large) {\n        .description {\n            font-size: 18px;\n        }\n\n        .pre_description {\n            font-size: 15px;\n        }\n\n        button {\n            margin-top: 64px;\n        }\n    }\n}"]}]);

// Exports
exports.locals = {
	"contentTitle": "contentTitle-module-contentTitle",
	"title": "contentTitle-module-title",
	"pre_description": "contentTitle-module-pre_description",
	"description": "contentTitle-module-description"
};