exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".carousel-module-carousel{margin:60px 0 96px;font-size:13px}.carousel-module-carousel .carousel-module-card{max-width:160px}.carousel-module-carousel img{width:100px;height:100px}.carousel-module-carousel .carousel-module-title{font-size:13px;font-weight:700;margin-top:16px}.carousel-module-carousel .carousel-module-description{margin-top:8px}", "",{"version":3,"sources":["/opt/buildhome/repo/src/components/Carousel/carousel.module.scss"],"names":[],"mappings":"AAGA,0BACI,kBAAA,CACA,cAAA,CAEA,gDACI,eAAA,CAGJ,8BACI,WAAA,CACA,YAAA,CAGJ,iDACI,cAAA,CACA,eAAA,CACA,eAAA,CAGJ,uDACI,cAAA","file":"carousel.module.scss","sourcesContent":["@import '../../assets/scss/uikit/variables';\n@import '../../assets/scss/global/variables-custom';\n\n.carousel {\n    margin: 60px 0 96px;\n    font-size: 13px;\n\n    .card {\n        max-width: 160px;\n    }\n\n    img {\n        width: 100px;\n        height: 100px;\n    }\n\n    .title {\n        font-size: 13px;\n        font-weight: 700;\n        margin-top: 16px;\n    }\n\n    .description {\n        margin-top: 8px;\n    }\n} "]}]);

// Exports
exports.locals = {
	"carousel": "carousel-module-carousel",
	"card": "carousel-module-card",
	"title": "carousel-module-title",
	"description": "carousel-module-description"
};