import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { handleActions } from "redux-actions";
import BrowserPersistence from "@overrides_venia/peregrine/util/simplePersistence";
import Cookies from "js-cookie";
import Pacchero from "@Src_main/assets/images/area-personale/icone/Pacchero.jpg";
import Tonno from "@Src_main/assets/images/area-personale/icone/Tonno.jpg";
import Fusillone from "@Src_main/assets/images/area-personale/icone/Fusillone.jpg";
import Carota from "@Src_main/assets/images/area-personale/icone/Carota.jpg";
import Broccolo from "@Src_main/assets/images/area-personale/icone/Broccolo.jpg";
import Pinolo from "@Src_main/assets/images/area-personale/icone/Pinolo.jpg";
var storage = new BrowserPersistence();
import actions from "../actions/user";
export var name = "user";
var isSignedIn = () => !!(storage.getItem("signin_token") || Cookies.get("signin_token_copy"));
var isApp = () => {
  var mediaCheck = window.matchMedia("(display-mode: standalone)").matches || window.matchMedia("(display-mode: fullscreen)").matches;
  var navigatorCheck = navigator.standalone;
  var andrefCheck = document.referrer.includes("android-app://");
  return mediaCheck || navigatorCheck || andrefCheck;
};
var initialState = {
  currentUser: {
    email: "",
    firstname: "",
    lastname: "",
    lastValidTelephoneNumber: ""
  },
  isUserComplete: false,
  hasUserMandatoryFields: false,
  hasUserOptionalFields: false,
  getDetailsError: null,
  isGettingDetails: false,
  isResettingPassword: false,
  isSignedInOnSessionStart: isSignedIn(),
  isSigningIn: false,
  isSignedIn: isSignedIn(),
  isApp: isApp(),
  resetPasswordError: null,
  token: storage.getItem("signin_token"),
  memberAttributes: [],
  guestCartId: null,
  avatars: [{
    "avatar": "Pacchero",
    "image": Pacchero,
    "isDefault": true
  }, {
    "avatar": "Tonno",
    "image": Tonno
  }, {
    "avatar": "Fusillone",
    "image": Fusillone
  }, {
    "avatar": "Carota",
    "image": Carota
  }, {
    "avatar": "Broccolo",
    "image": Broccolo
  }, {
    "avatar": "Pinolo",
    "image": Pinolo
  }]
};
var reducerMap = {
  [actions.setToken]: (state, _ref) => {
    var {
      payload
    } = _ref;
    return _objectSpread(_objectSpread({}, state), {}, {
      isSignedIn: true,
      token: payload
    });
  },
  [actions.setGuestCartId]: (state, _ref2) => {
    var {
      payload
    } = _ref2;
    return _objectSpread(_objectSpread({}, state), {}, {
      guestCartId: payload
    });
  },
  [actions.clearToken]: state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      isSignedIn: false,
      isSignedInOnSessionStart: false,
      token: null,
      currentUser: {
        email: "",
        firstname: "",
        lastname: "",
        lastValidTelephoneNumber: ""
      }
    });
  },
  [actions.getDetails.request]: state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      getDetailsError: null,
      isGettingDetails: true
    });
  },
  [actions.getDetails.receive]: (state, _ref3) => {
    var _payload$addresses, _payload$addresses$fi;
    var {
      payload,
      error
    } = _ref3;
    if (error) {
      return _objectSpread(_objectSpread({}, state), {}, {
        getDetailsError: payload,
        isGettingDetails: false
      });
    }
    var lastValidTelephoneNumber = (_payload$addresses = payload.addresses) === null || _payload$addresses === void 0 ? void 0 : (_payload$addresses$fi = _payload$addresses.find(address => !!address.telephone && address.telephone !== "anon")) === null || _payload$addresses$fi === void 0 ? void 0 : _payload$addresses$fi.telephone;
    var avatar = state.avatars.find(av => av.avatar === payload.avatar.avatar);
    var payloadAvatar = _objectSpread(_objectSpread({}, payload), {}, {
      avatar: avatar
    });
    var payloadEnriched = lastValidTelephoneNumber ? _objectSpread(_objectSpread({}, payloadAvatar), {}, {
      lastValidTelephoneNumber: lastValidTelephoneNumber
    }) : payloadAvatar;
    return _objectSpread(_objectSpread({}, state), {}, {
      currentUser: payloadEnriched,
      hasUserMandatoryFields: !!payload.firstname,
      hasUserOptionalFields: !!(payload.date_of_birth && payload.gender),
      getDetailsError: null,
      isGettingDetails: false
    });
  },
  [actions.resetPassword.request]: state => _objectSpread(_objectSpread({}, state), {}, {
    isResettingPassword: true
  }),
  // TODO: handle the reset password response from the API.
  [actions.resetPassword.receive]: (state, _ref4) => {
    var {
      payload,
      error
    } = _ref4;
    if (error) {
      return _objectSpread(_objectSpread({}, state), {}, {
        isResettingPassword: false,
        resetPasswordError: payload
      });
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      isResettingPassword: false,
      resetPasswordError: null
    });
  },
  [actions.reset]: () => {
    return _objectSpread(_objectSpread({}, initialState), {}, {
      isSignedIn: false,
      isSignedInOnSessionStart: false,
      token: null,
      currentUser: {
        email: "",
        firstname: "",
        lastname: "",
        lastValidTelephoneNumber: ""
      }
    });
  },
  [actions.isSigningIn.submit]: (state, _ref5) => {
    var {
      payload
    } = _ref5;
    return _objectSpread(_objectSpread({}, state), {}, {
      isSigningIn: payload
    });
  }
};
export default handleActions(reducerMap, initialState);